.side-panel-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(12, 23, 38, 0.8);
  backdrop-filter: blur(4px);
  z-index: 201;

  .side-panel {
    position: fixed;
    left: 0;
    top: 0;
    width: 360px;
    height: 100vh;
    max-height: 100%;
    background-color: $color-pms-black-6;
    z-index: 202;
    overflow-y: auto;

    &.add-padding {
      padding-bottom: 180px;
    }

    @media screen and (max-width: 430px) {
      width: 100%;
    }

    .close {
      padding: 24px;
      cursor: pointer;

      .icon-text-block {
        font-family: 'Jost*';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;

        padding: 12px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 8px;
        letter-spacing: 1px;
      }
    }
  }
}
