// Global grid break points need to be declared before Bootstrap
@import "./assets/styles/variables";

@import "../node_modules/bootstrap/scss/bootstrap";

@import "../src/assets/styles/fonts";

// Global colors
@import "./assets/styles/global-colors";
// Global styles
@import "./assets/styles/global-styles";
// Global buttons
@import "./assets/styles/buttons";

@import "./styles/components/Loader";
@import "./styles/components/Header.scss";
@import "./styles/components/VideoWrapper.scss";
@import "./styles/components/HeroSection.scss";
@import "./styles/components/ServiceBlock.scss";
@import "./styles/components/ServicesWrapper.scss";
@import "./styles/components/CustomCaroulsel.scss";
@import "./styles/components/SidePanel.scss";
@import "./styles/components/TabSwitcher.scss";
@import "./styles/components/SidePanelPlatform.scss";
@import "./styles/components/SidePanelSubscriptions.scss";
@import "./styles/components/Footer.scss";
@import "./styles/components/Accordion.scss";
@import "./styles/components/Switcher.scss";
@import "./styles/components/Modal.scss";
@import "./styles/components/SidePanelLoginForm.scss";

@import "./styles/screens/template.scss";
@import "./styles/screens/index.scss";
@import "./styles/screens/myaccount.scss";
@import "./styles/screens/login.scss";

:root {
  color: $primary-color;
  font-family: "Jost*";
}

body {
  background-color: $color-pms-black-6;
  font-family: "Jost*";
  color: $primary-white;
  position: relative;
}

.container-fluid {
  padding: 0;
  overflow: hidden;
}

.header-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  overflow: visible;

  &.dark {
    background-color: $color-pms-black-6;
  }
}

.text-container {
  margin-top: 100px;
  position: relative;
  display: inherit;
  color: $grey-200;

  table {
    border: 1px solid $primary-white;
    margin-bottom: 24px;

    thead {
      background: $primary-color-800;
    }

    th,
    tbody,
    td {
      padding: 10px;
      border: 1px solid $primary-white;
    }
  }
}

.text-container a,
.btn-tnc {
  color: #0d6efd !important;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: transparent;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  margin-left: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #888;
  margin-left: 10px;
}

iframe {
  display: none;
}

#ot-sdk-btn {
  position: absolute;
  bottom: 30px;
  left: 30px;
  color: $primary-white !important;
  border-color: $primary-white !important;
  font-family: 'Jost*';

  &:hover {
    background-color: transparent !important;
  }

  @media screen and (max-width: 430px) {
    display: none !important;
  }
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title,
.ot-sdk-cookie-policy-group,
#cookie-policy-description,
.ot-sdk-cookie-policy-group-desc,
th.ot-table-header,
td.ot-host-td,
td.ot-cookies-type span,
td.ot-life-span-td span {
  color: $grey-200 !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a {
  background-color: $eternal-blue !important;
  border-bottom: 1px solid #0d6efd;
  color: #0d6efd !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a:hover {
  background-color: #fff !important;
}