button {
  background: none;
  color: none;
  border: none;
  padding: 0;
  font: none;
  outline: none;
  cursor: pointer;
}
.btn-primary {
  border-radius: 2px;
  gap: 8px;
  border: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  padding: 12px 24px;
  height: 48px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;

  background-color: $primary-color;
  color: $primary-white;
  svg {
    path {
      stroke: $primary-white;
    }
  }
}

.btn-primary-small {
  border-radius: 2px;
  gap: 8px;
  border: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  height: 40px;
  padding: 12px 14px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 150%;
  background-color: $primary-color;
  color: $primary-white;
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: $primary-white;
    }
  }
}
.btn-wide {
  width: 100%;
}
.btn-primary:hover,
.btn-primary-small:hover,
.btn-primary:active,
.btn-primary-small:active {
  outline: none;
  background-color: $primary-color-900;
  box-shadow: none;
  border-color: $primary-color-900;
}

.btn-secondary {
  border-radius: 2px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  padding: 12px 24px;
  height: 48px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;
  background-color: $primary-white;
  color: $primary-color;
  border: 1px solid $primary-color;

  svg {
    path {
      stroke: $primary-color;
    }
  }
}
.btn-secondary-outline-white {
  border-radius: 0px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;

  padding: 4px 10px;
  height: 32px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;
  background-color: $color-highlight-tone-brown;
  color: $primary-white;
  //border: 1px solid $primary-white;

  svg {
    path {
      stroke: $primary-white;
    }
  }

  &:active,
  &:hover {
    background-color: $color-natural-tone-red;
    color: $primary-white;
  }
}

.show.btn-secondary-outline-white {
  background-color: $color-natural-tone-red;
  color: $primary-white;
}

.btn-capitalize { 
  text-transform: capitalize;
}

.btn-secondary-small {
  border-radius: 2px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  height: 40px;
  padding: 12px 14px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 150%;
  background-color: $primary-white;
  color: $primary-color;
  border: 1px solid $primary-color;
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: $primary-color;
    }
  }
}
.btn-secondary:hover,
.btn-secondary-small:hover,
.btn-secondary:active,
.btn-secondary-small:active {
  outline: none;
  box-shadow: none;
  background-color: $primary-white;
  border-color: $primary-color-900;
  color: $primary-color-900;
}

.btn-primary-outline {
  border-radius: 2px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  padding: 12px 24px;
  height: 48px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;

  background-color: $primary-color;
  border: 1px solid $primary-white;
  color: $primary-white;
  svg {
    path {
      stroke: $primary-white;
    }
  }
}

.btn-primary-small-outline {
  border-radius: 2px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  height: 40px;
  padding: 12px 14px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 150%;
  background-color: $primary-color;
  border: 1px solid $primary-white;
  color: $primary-white;
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: $primary-white;
    }
  }
}

.btn-primary-outline:hover,
.btn-primary-small-outline:hover,
.btn-primary-outline:active,
.btn-primary-small-outline:active {
  background-color: $primary-color-900;
  box-shadow: none;
  border-color: $primary-color-900;
  color: $primary-white;
}

.view-type-button {
  border-radius: 4px;

  height: 44px;
  background: $grey-900;
  color: $primary-white;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: default;
  svg {
    margin-right: 8px;
    path {
      stroke: $primary-white;
    }
  }
  &.active {
    background: #f37021;
    border: 0.5px solid #f68a46;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
  }
}

.btn-primary-warning {
  border-radius: 2px;
  gap: 8px;
  border: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  padding: 12px 24px;
  height: 48px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;

  background-color: $error-color-1000;
  color: $primary-white;
  svg {
    path {
      stroke: $primary-white;
    }
  }
}

.btn-primary-warning-small {
  border-radius: 2px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  height: 40px;
  padding: 12px 14px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 150%;
  border: 1px solid $error-color-1000;
  background-color: $error-color-1000;
  color: $primary-white;
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: $primary-white;
    }
  }
}

.btn-primary-confirm {
  border-radius: 2px;
  gap: 8px;
  border: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  padding: 12px 24px;
  height: 48px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;

  background-color: $success-color-1000;
  color: $primary-color;
  svg {
    path {
      stroke: $primary-color;
    }
  }
}
.flex1 {
  flex: 1;
}

.pagination-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  &:hover {
    border: 1px solid $primary-color;
  }
}
.pagination-btn-active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 40px;
  height: 40px;
  background: $primary-color;
  border-radius: 2px;
  color: $primary-white;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.btn-primary-brown {
  border-radius: 0px;
  width: 160px;
  gap: 8px;
  border: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  padding: 12px 24px;
  height: 48px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: capitalize;

  background-color: $color-highlight-tone-brown;
  color: $primary-white;
  svg {
    path {
      stroke: $primary-white;
    }
  }
}

.btn-primary-brown:hover {
  background-color:  $color-natural-tone-red;
  color:$primary-white;
}

.btn-primary-trans {
  border-radius: 2px;
  gap: 8px;
  border: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  padding: 12px 24px;
  height: 48px;
  font-family: "Jost*";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;

  background-color: transparent;
  color: $primary-white;
  svg {
    path {
      stroke: $primary-white;
    }
  }
}

button[disabled] {
  cursor: not-allowed;
  background-color: $grey-500;
}