.panel-subscriptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;

  .panel-subscriptions-text {
    text-align: center;
    padding: 24px 0px;
  }
}
