.side-panel-login-form-wrapper {
  position: fixed;
  left: 0;
  width: 360px;
  bottom: 0;
  height: 180px;
  padding: 24px 16px 24px 16px;
  background-color: $color-pms-black-6;

  .side-panel-login-form {
    padding: 16px 24px;
    border-radius: 4px;
    background-color: $color-pms-black-6;

    .panel-subscriptions-text {
      text-align: center;
      padding-bottom: 16px;
    }
  }
}
