@media screen and (max-width: 480px) {
  .services-wrapper {
    display: none;

    &.template {
      display: flex;
      padding: 100px 10px 30px 10px;

      .launch-button {
        width: 100%;
      }
    }
  }

  .footer-wrapper {
    display: none;

    &.template-footer {
      display: block;
      padding-top: 25px;
    }

    &.myaccount-footer {
      display: block;
      padding-top: 30px;
      bottom: 0%;
    }
  }
}

.services-wrapper.filtered {
  .carousel-container>.custom-carousel>.custom-carousel-wrapper>.carousel-items>.single-item {
    margin: 0;
  }
}