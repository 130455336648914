// Global colors
@import "../../assets/styles/global-colors";

* {
    font-family: "Jost*";

    .myprofile {
        padding: 100px 340px 15vh 300px;
        background: inherit;
        color: inherit;
        width: 100vw;

        .myprofile-heading {
            font-family: "Jost*";
            font-size: 36px;
            font-style: normal;
            font-weight: 350;
            line-height: 110%;
            text-transform: uppercase;
            padding: 30px 0;

            .center {
                padding-right: 10px;
            }
        }

        .changePwd-button {
            margin: 5% 0 0 0%;
            // background-color: #2e4164;
            background-color: $eternal-blue;
        }

        .noneditable-btn {
            cursor: not-allowed;
            background-color: $grey-500;
        }

        .myprofile-container {
            font-size: 24px;
            font-style: normal;
            font-weight: 350;
            line-height: 110%;
            padding: 3vw 0 0 6vw;

            .action-buttons {
                .launch-button {
                    padding: 12px;
                    gap: 8px;
                    width: 6rem;
                    height: 48px;
                    color: inherit;
                    cursor: pointer;
                    margin: 18px 48px 18px 0;
                    background-color: $color-highlight-tone-brown;
                    border: 1px solid $primary-white;

                    &:hover {
                        background-color: $color-natural-tone-red;
                        color: $primary-white;
                    }
                }

                @media screen and (min-width:768px) and (max-width: 1023px) {
                    display: flex;
                    // flex-direction: column;

                    .launch-button {
                        width: 13.5vw;
                        // flex-direction: column;
                        flex-wrap: wrap;
                    }
                }

                @media screen and (max-width:767px) and (min-width: 350px) {
                    .launch-button {
                        width: 21vw;
                        flex-direction: row;
                        margin: 18px 18px 18px 0;
                    }
                }

                .noneditable-btn {
                    cursor: not-allowed;
                    background-color: $grey-500;
                }
            }

            .input-noneditable {
                cursor: not-allowed;
            }

            .form_data_validation_error {
                font-size: 13px;
                font-style: inherit;
                color: $error-color-900;
                font-weight: 400;
                /* background-color: red; */
                padding: 2px 10px;
                text-wrap: nowrap;
            }
        }

        .user-parameter {
            font-size: 18px;
            font-weight: 400;


            .user-parameter-heading {
                font-size: 24px;
                font-weight: 350;
            }

        }

        @media screen and (max-width: 1024px) {
            padding: 14vw 20vw 15vh 20vw;
            // width: 83vw;

            .myprofile-heading {
                font-size: 25px;
                padding: 20px 0;
            }
        }

        @media screen and (min-width: 360px) and (max-width: 540px) {
            padding: 100px 50px 15vh 50px;

            .myprofile-container {
                padding: 0 0 0 2vw;
            }

            .myprofile-heading {
                font-size: 25px;
            }
        }

        @media screen and (min-width: 540px) and (max-width: 768px) {
            padding: 14vw 20vw 15vh 20vw;

            .myprofile-container {
                padding: 0 0 0 2vw;
            }
        }
    }
}