.switcher {
  height: 75%;
  position: absolute;
  width: 100%;
  transition: height 500ms;

  &.fixed {
    position: static;
    left: 50%;
    object-fit: contain;
  }

  .images {
    max-height: 70%;
    position: relative;
    border-radius: 8px;
    transition: height 500ms, min-height 500ms;

    .image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 8px;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      transition: opacity 1s;
      z-index: 0;

      &.active {
        opacity: 1;
        z-index: 3;
      }

      video {
        width: 90%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .dots {
    height: 60px;
    z-index: 5;

    .dot {
      padding: 20px 10px;
      width: 28px;
      height: 48px;
      transition: width 300ms;

      &>div {
        background-color: $grey-500;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        transition: width 300ms, background-color 300ms;
        cursor: pointer;
      }

      &.active {
        width: 68px;

        &>div {
          background-color: $blue-1000;
          width: 40px;
          height: 8px;
          border-radius: 8px;
        }
      }
    }
  }
}