.tab-switcher {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  //align-items: center;
  padding: 0 24px;

  .tab-header {
    border-bottom: 1px solid $color-highlight-tone-brown;
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    font-size: 10px;
    padding: 14.5px;

    &.active {
      border-bottom: 1px solid $primary-white;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.tab-switcher-content {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}
