.services-wrapper {
  padding: 80px 100px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 1921px) {
    min-width: 1920px;
  }

  .heading {
    font-family: "Jost*";
    font-weight: 200;
    text-transform: capitalize;
  }

  .explore-list, .explore-data {
    flex-wrap: nowrap;
  }

  .services-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
  }

  .services-bottom-spacing {
    margin-bottom: 80px;
  }

  .login-block {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: $primary-color-800;

    .panel-subscriptions-text {
      text-align: center;
      margin-bottom: 16px;
    }
  }

  .service-block-special {
    margin-right: 32px;
    padding: 0;
    height: 435px;
    max-width: 260px;
    width: 260px;
    background-color: $primary-color-800;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .image-block {
      height: 355px;
      width: 260px;
      transition: height 300ms;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .service-info-block {
      margin: 16px 16px 8px 16px;

      h5 {
        margin: 0;
        padding: 0;
      }
    }

    .service-info-block-text {
      margin: 0 16px 16px 16px;
      font-size: 16px;
      color: $grey-100;
      height: 0;
      transition: height 300ms, opacity 300ms;
      opacity: 0.0;
    }

    .button-wrapper {
      height: 0;
      padding: 0;
      transition: height 300ms, padding 300ms;

      button {
        opacity: 0;
        transition: opacity 300ms;
      }
    }

    &:hover {
      .service-info-block-text {
        height: auto;
        opacity: 1.0;
      }

      .button-wrapper {
        height: 80px;
        padding: 0 16px 16px 16px;

        button {
          opacity: 1;
        }
      }

      .image-block {
        height: 294px;
        width: 260px;
      }
    }

  }
}

.graphic-line-service-wrapper {
  width: 100%;
  height: 1px;
  background-color:  $primary-white;
  margin: 16px 0;

  &.service-page {
    margin: 0px;
  }
}
