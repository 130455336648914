.accordion-list {
  background-color: $color-pms-black-6;
  border-radius: 0px;
  overflow: hidden;

  .accordion-item {
    border-color: transparent;
    border: 0;
    background-color: $color-highlight-tone-brown;
    margin-bottom: 5px;

    .accordion-header {
      button {
        border-color: transparent;
        padding: 32px 32px 0 32px;
        font-family: 'Jost*';
        font-style: normal;
        font-weight: 350;
        font-size: 20px;
        line-height: 160%;
        letter-spacing: 0.3px;
        color: $primary-white;
        background-color: $color-highlight-tone-brown;
        outline-color: transparent;
        box-shadow: none;
        border-radius: 4px 4px 0 0;
        text-transform: capitalize;

        &:after {
          background-image: url('data:image/svg+xml,<svg width="22" height="2" viewBox="0 0 22 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H21" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>')
        }

        &.collapsed {
          background-color: $color-highlight-tone-brown;
          padding-bottom: 32px;
          border-radius: 0;

          &:after {
            background-image: url('data:image/svg+xml,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 11H21M11 1L11 21" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          }
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .accordion-collapse {
      background-color: $color-highlight-tone-brown;
      border-color: transparent;
      border-radius: 0 0 4px 4px;

      .accordion-body {
        padding: 16px 32px 32px 32px;
        font-family: 'Jost*';
        font-style: normal;
        font-weight: 200;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.3px;
        color: $primary-white;

        .accordion-link {
          margin-top: 32px;
        }

        .btn-accordion {
          font-family: "Jost*";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          text-transform: uppercase;
          border-color: transparent;
          color: $primary-white;
          border: 1px solid $primary-white;
          padding: 12px 24px;
          border-radius: 0px;

          svg {
            path {
              stroke: $primary-white;
            }
          }

          &:hover {
            background-color: $color-natural-tone-red;
          }
        }
      }
    }
  }
}
