.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(31, 31, 31, 0.8);
  backdrop-filter: blur(4px);
  z-index: 205;

  .modal-box {
    background-color: $primary-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 32px;
    max-width: 500px;
    min-width: 450px;
    min-height: 277px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    z-index: 205;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    &.big {
      &.full {
        width: 90%;
        max-width: 90%;
      }

      max-width: 800px;
      max-height: 90%;

      .modal-body {
        margin-bottom: 10px;
      }

      .modal-header {
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
      }

      &.left {
        .modal-header {
          justify-content: left;
        }
      }
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 64px;
      margin-bottom: 16px;

      button.close {
        position: absolute;
        top: 32px;
        right: 32px;

        svg {
          path {
            stroke: $primary-color;
          }
        }
      }
    }

    .modal-body {
      margin-bottom: 64px;
      overflow: auto;

      .accept-offer-text {
        margin-bottom: 24px;
      }

      .description-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        margin-bottom: 24px;

        .first-letter-cap {
          text-transform: capitalize;
        }
      }
    }

    .modal-footer {
      gap: 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      button {
        white-space: nowrap;
      }
    }
  }
}
