.template {
  padding-top: 50px;

  @media screen and (min-width: 480px) {
    padding-left: 88px;
  }

  h1 {
    font-family: "Jost*";
    font-size: 40px;
    font-weight: 200;
    line-height: 50px;
    text-align: left;
    color: $primary-white;
    text-transform: capitalize;

    @media screen and (max-width: 1024px) {
      font-family: "Jost*";
      font-style: normal;
      font-weight: 350;
      font-size: 36px;
      line-height: 110%;
    }

  }

  .subheading {
    font-family: "Jost*";
    font-size: 25px;
    font-weight: 300;

    @media screen and (max-width: 1024px) {
      font-size: 26px;
    }
  }

  .launch-button {
    padding: 12px;
    gap: 8px;
    width: 133px;
    height: 48px;
    margin: 20px 8px 20px 0;
    color: $primary-white;
    cursor: pointer;
  }

  .about-page {
    h3 {
      font-family: "Jost*";
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      line-height: 140%;
      color: $primary-white;
    }
  }

  .description {
   // margin-top: 24px;
    font-family: "Jost*";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.3px;
    color: $primary-white;
  }

  .list {
    margin-top: 24px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .launch-button {
      margin: 32px 32px 48px 0;
    }

    .switcher {
      width: 100%;
      min-height: 100%;
      margin-bottom: 48px;
      position: static;

      .images {
        position: relative;
        min-height: 600px;
        height: 100%;
      }
    }
  }
}

.template-services-background-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 120px;
  padding-left: 100px;

  @media screen and (max-width: 480px) {
    padding-left: 20px;
  }
}

.template-services-header-section {
  display: flex;
  flex-direction: column;
  max-width: 35vw;

  @media screen and (max-width: 480px) {
    max-width: 90vw;
  }

  h1 {
    font-family: "Jost*";
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
    text-align: left;
    color: $primary-white;
    text-transform: capitalize;

    @media screen and (max-width: 1024px) {
      font-family: "Jost*";
      font-style: normal;
      font-weight: 350;
      font-size: 36px;
      line-height: 110%;
    }

  }

  .subheading {
    font-family: "Jost*";
    font-size: 25px;
    font-weight: 300;

    @media screen and (max-width: 1024px) {
      font-size: 26px;
    }
  }
}

.template-services-header {
  display: block;
  max-width: fit-content;
}

.template-services-graphic-line {
  width: auto;
  height: 15px;
  background-color:  $primary-white;
  margin: 12px 0;
}

.btn-group {
  display: flex;
  gap: 10px;
  padding-top: 50px;
  padding-bottom: 20px;
}