.hero-section {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  padding-left: 100px;
  padding-right: 100px;
  width: 100vw;

  @media screen and (max-width: 430px) {
    padding: 20px;
    display: block;
  }

  @media screen and (max-width: 360px) {
    padding: 10px;
  }

  @media screen and (min-width: 430px) and (max-width: 710px) {
    padding: 50px;
  }

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed;
    background-position: center;
    background-position: 50% !important;

    @media screen and (max-width: 430px) {
      width: auto;
    }
  }

  .background-video::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(100% 316.05% at 0% 100%, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .hero-background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/bubles.png");
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.1;
    z-index: -1;
  }

  .hero-section-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    max-width: 1920px;

    @media screen and (min-width: 1921px) {
      min-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 430px) {
      align-items: center;
      text-align: center;
      padding-top: 75px;
    }

    .headline1, .headline2 {
      font-family: "Jost*";
      font-style: normal;
      text-transform: none;
      font-weight: 200;
      font-size: 40px;
      line-height: 125%;
      width: 850px;
      opacity: 1;

      @media screen and (max-width: 430px) {
        width: 327px;
        height: 125px;
        font-family: "Jost*";
        font-style: normal;
        font-weight: 350;
        font-size: 35px;
        line-height: 125%;
        letter-spacing: -2px;
      }

      @media screen and (min-width: 430px) and (max-width: 710px) {
        width: 327px;
        height: 140px;
        font-size: 40px;
        padding-top: 10px;
      }
    }

    .headline1 {
      font-weight: 500;
      width: fit-content;
    }
    
    .headline2 {
      font-weight: 100;

      @media screen and (min-width: 710px) {
        &.loggedIn-hero-section {
          max-width: 35vw;
        }
      }
    }

    .slogan {
      margin-top: 20px;
      letter-spacing: -0.2px;

      @media screen and (min-width: 710px) {
        &.loggedIn-hero-section {
          max-width: 35vw;
        }
      }
    }

    .graphic-line {
      width: auto;
      height: 15px;
      background-color:  $primary-white;
      margin: 12px 0;
    }    

    .sign-in-graphic-line {
      width: auto;
      height: 15px;
      background-color:  $color-highlight-tone-brown;
      margin: 16px 0;
    }

    h5 {
      width: 552px;
      opacity: 1;
      font-size: 32px;
      font-size: 27px;
      font-family: "Jost*";
      font-weight: 300;
      line-height: 125%;
      letter-spacing: -0.3px;

      @media screen and (max-width: 430px) {
        width: 100%;
        font-size: auto;
      }

      @media screen and (min-width: 430px) and (max-width: 710px) {
        width: 100%;
        font-size: 30px;
      }
    }

    .sign-in {
      padding-top: 4rem;
      z-index: 10;
    }

    .sign-in-text {
      font-family: "Jost*";
      font-style: normal;
      text-transform: capitalize;
      font-weight: 300;
      font-size: 20px;
      margin-bottom: 15px;
      letter-spacing: 0.4px;

      @media screen and (max-width: 430px) {
        width: 375px;
      }
    }

    .sign-in-btn {
      display: flex;
      justify-content: center;
    }

    .info-button-wrapper {
      margin-top: 38px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      opacity: 1;

      @media screen and (min-width: 480px) {
        .btn-primary-brown {
          display: none;
        }
      }

      @media screen and (max-width: 479px) {
        flex-direction: column;
        width: 100%;
        align-items: center;

        button {
          width: 100%;
        }
      }

      @media screen and (max-width: 400px) {
        flex-direction: column;
        width: 90%;
        align-items: center;

        button {
          width: 100%;
        }
      }

      @media screen and (max-width: 370px) {
        flex-direction: column;
        width: 80%;

        button {
          width: 100%;
        }
      }

      .info {
        display: none;
        font-family: "Jost*";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.3px;
      }
    }
  }

  .block-more {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 479px) {
      display: none;
    }

    .explore-products {
      display: flex;
      flex-direction: column;
      align-items: center;

      .circle-down-arrow{
        cursor: pointer;
        width: 60px;
        height: 60px;
        margin-bottom: 16px;
        border-radius: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }

      .circle {
        padding-top: 16px;
        padding-bottom: 16px;

        #lottie {
          background-color: $color-highlight-tone-brown !important;
          border-radius: 100%;
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

          svg {
            width: 97% !important;
            height: 97% !important;
          }

          &:hover {
            background-color: $color-natural-tone-red !important;
          }
        }
      }

      .text {
        font-family: "Jost*";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
      }
    }
  }
}

.video-wrapper {
  margin-top: 20px;
  width: 40vw;
  padding-left: 40px;

  @media screen and (max-width: 710px) {
    min-width: 70vw;
    padding-left: 0px;
  }

  @media screen and (max-width: 430px) and (max-height: 750px) {
    display: none;
  }
}

.iframe-wrapper {
  padding: 56.25% 0px 0px;
  position: relative;
}

.iframe-hero-section {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: block;
}

.text-and-video-container {
  width: 85vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 710px) {
    flex-direction: column;    
  }
}

.hero-section-text-left {

  @media screen and (max-width: 710px) {
    display: flex;
    flex-direction: column;
  }
  
  @media screen and (max-width: 430px) {
    align-items: center;
  }
}

.text-and-video-container.sign-in-hero-section {
  width: auto;
}

.hero-section-text-left.sign-in-hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}