.loader-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 210;
  background-color: rgba(31, 31, 31, 0.8);
  backdrop-filter: blur(4px);

  .loader {
    z-index: 211;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #999AA9;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  .error-message {
    z-index: 211;
    width: 250px;
    position: absolute;
    top: calc(50% + 42px);
    left: calc(50% - 125px);
    height: 48px;
    color: #999AA9;

    a {
      position: relative;
      top: 6px;
      color: $orange-1000;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
