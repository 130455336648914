.carousel-container {
  min-width: 100%;

  .custom-carousel {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    .button-holder-left,
    .button-holder-right {
      height: 100%;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }

    .button-holder-left {
      left: -24px;

      .button-left {
        z-index: 1;
        width: 48px;
        height: 48px;
      }
    }

    .button-holder-right {
      right: -24px;

      .button-right {
        z-index: 1;
        width: 48px;
        height: 48px;
      }
    }

    .custom-carousel-wrapper {
      overflow: hidden;
      max-width: calc(100% - 64px);
      min-width: 100%;
      display: flex;
      position: relative;
      height: 435px;

      .carousel-items {
        display: flex;
        transition: transform 0.3s ease-in-out;
        position: absolute;

        .single-item {
          margin-right: 30px;
          box-sizing: border-box;
        }
      }
    }
  }
}