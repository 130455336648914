h1 {
  /* H1/Medium */

  font-style: normal;
  font-size: 48px;
  line-height: 125%;
  letter-spacing: -0.3px;
}

h2 {
  /* H2/Medium */

  font-style: normal;
  font-size: 40px;
  line-height: 130%;
  letter-spacing: -0.2px;
}

h3 {
  font-style: normal;
  font-size: 32px;
  line-height: 140%;
}

h4 {
  /* H4/Medium */

  font-style: normal;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.2px;
}

h5 {
  /* H5/Medium */

  font-style: normal;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.3px;
}

// ------------------------------
// Global text styles
// ------------------------------
.subtitle {
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
}

.body-large {
  /* Body Large/Light */

  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.3px;
}

.body-small {
  /* Body Small/Light */

  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.3px;
}

.body-x-small {
  /* Body X-Small/Light */

  font-style: normal;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.4px;
}

.caption-small {
  font-style: normal;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.5px;
}

.caption-large-upper {
  /* Caption Large Upper/Light */

  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 1.5px;
  text-transform: capitalize;
}

.button-default-text {
  /* Button/Default */

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;
}

.button-small-text {
  /* Button/Small */

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.5px;
}

.header-text-block {
  margin: 32px;
}

.color-primary {
  color: $primary-color;
}

.color-error {
  color: $error-color;
}

a {
  width: 100%;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  outline: none;
}

.light {
  font-family: 'Jost*' !important;
  font-weight: 200 !important;
}

.medium {
  font-family: "Jost*" !important;

  font-weight: 350 !important;
}

.heavy {
  font-family: "Jost*" !important;

  font-weight: 600 !important;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-align: center;

  @media screen and (min-width: 710px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.policy-headingTnC1 {
  padding-right: 2rem;

  @media screen and (max-width: 660px) {
    padding-right: 0rem;
  }
}

.policy-headingTnC2 {
  padding-right: 5.2rem;

  @media screen and (max-width: 660px) {
    padding-right: 0rem;
  }
}

.policy-headingPP1 {
  padding-right: 3rem;

  @media screen and (max-width: 660px) {
    padding-right: 0;
  }
}

.policy-headingPP2 {
  padding-right: 6rem;

  @media screen and (max-width: 660px) {
    padding-right: 0;
  }
}

.policy-headingC1 {
  padding-right: 2rem;

  @media screen and (max-width: 660px) {
    padding-right: 0;
  }
}

.policy-headingC2 {
  padding-right: 5rem;

  @media screen and (max-width: 660px) {
    padding-right: 0;
  }
}