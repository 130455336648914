.main-header {
  position: sticky;

  .logo-medium {
    font-size: 1rem;
    height: auto;
    width: 290px;
  }

  .home-icon {
    float: left;

    &:hover {
      cursor: pointer;
    }

    .home-icon-img {
      width: 40px;
      height: 32px;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      //padding: 34px 8px 24px 110px;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      //padding: 34px 8px 24px 6rem;
    }

    @media screen and (max-width: 435px) {
      //padding: 34px 4rem 24px 1rem;
    }

    @media screen and (min-width: 435px) and (max-width: 700px) {
      //padding: 34px 8px 24px 4rem;
    }

    @media screen and (min-width: 700px) and (max-width: 768px) {
      //padding: 34px 8px 24px 6rem;
    }

    @media screen and (min-width: 1201px) and (max-width: 1300px) {
      //padding: 34px 8px 24px 100px;
    }
  }

  .beforeLoginFooter {
    padding: 34px 9vw 24px 6vw;

    @media screen and (min-width: 1025px) and (max-width: 1410px) {
      padding: 34px 9vw 24px 6vw;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 34px 4vw 24px 5vw;
    }

    @media screen and (max-width: 435px) {
      padding: 34px 4rem 24px 1rem;
    }

    @media screen and (min-width: 435px) and (max-width: 768px) {
      padding: 34px 3rem 24px 2rem;
    }

    @media screen and (min-width: 1410px) and (max-width: 2100px) {
      padding: 34px 5vw 24px 11vw;
    }

    @media screen and (min-width: 2100px) and (max-width: 2560px) {
      padding: 34px 0px 24px 25vw;
    }
  }

  .afterLoginFooter {
    padding: 34px 9vw 24px 6vw;

    @media screen and (min-width: 1025px) and (max-width: 1410px) {
      padding: 34px 8px 24px 6vw;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 34px 8px 24px 5vw;
    }

    @media screen and (max-width: 435px) {
      padding: 34px 4rem 24px 1rem;
    }

    @media screen and (min-width: 435px) and (max-width: 768px) {
      padding: 34px 8px 24px 2rem;
    }

    @media screen and (min-width: 1410px) and (max-width: 2100px) {
      padding: 34px 8px 24px 11vw;
    }

    @media screen and (min-width: 2100px) and (max-width: 2560px) {
      padding: 34px 8px 24px 25vw;
    }
  }

  .header-right-section {
    display: flex;
    align-items: center;
  }

  .header {
    flex: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0px 24px 0px;
    top: 0;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;

    .header-logo-icon {
      display: none;

      @media screen and (max-width: 430px) {
        display: block;
      }

      &:hover {
        cursor: pointer;
      }
    }

    // .products-opener {
    //   padding: 12px 24px 12px 0px;
    // }

    @media screen and (max-width: 645px) {
      padding: 24px 0px 24px 0px;

      &>.products-opener {
        display: none;
      }
    }

    &.dark {
      background-color: $eternal-blue;
      opacity: 0.9;
    }

    &.header-afterLogin {
      padding: 24px 100px 24px 100px;

      @media screen and (max-width: 710px) {
        padding-left: 50px;
      }

      @media screen and (max-width: 374px) {
        padding-left: 30px;
      }
    }

    .header-logo-afterLogin {
      padding-right: 20px;
      align-self: center;

      @media screen and (max-width: 660px) {
        display: none;
      }

      svg {
        path {
          fill: $primary-white;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .afterLogin-footer-page {
      @media screen and (min-width: 1025px) and (max-width: 1410px) {
        padding-left: 2vw;
      }

      @media screen and (min-width: 1410px) and (max-width: 1440px) {
        padding-right: 2vw;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        padding-left: 5vw;
      }

      @media screen and (min-width: 1441px) and (max-width: 2560px) {
        padding-right: 15vw;
      }
    }

    .header-logo-beforeLogin {
      align-self: center;
      // padding-right: 14vw;

      svg {
        path {
          fill: $primary-white;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .footer-page {
      padding-right: 14vw;

      @media screen and (min-width: 1025px) and (max-width: 1440px) {
        padding-right: 18vw;
      }

      @media screen and (min-width:1560px) and (max-width:2560px) {
        padding-right: 26vw;
      }
    }

    .dropdown {
      max-width: 53vw;
      min-width: 21vw;

      button {
        width: 100%;

        &::after {
          content: "";
          width: 12px;
          height: 16px;
          background:no-repeat;
          background-image: url("../../assets/icons/DownArrowIcon.svg");
        }

        @media screen and (max-width: 645px) {
          width: 100%;
        }

        @media screen and (max-width:360px) {
          text-wrap: wrap;
        }
      }

      @media screen and (min-width: 374px) and (max-width: 645px) {
        min-width: 44vw;
      }

      @media screen and (min-width: 645px) and (max-width: 950px) {
        min-width: 25vw;
      }

      @media screen and (max-width: 374px) {
        max-width: 60vw;
        min-width: 57vw;
      }

      @media screen and (min-width: 1150px) {
        min-width: 15vw;
      }
    }

    .dropdown-toggle::after {
      border: transparent;
    }

    .graphic-line-logout {      
      min-width: 91%;
      height: 0.5px;
      background-color:  $primary-white;
      margin: 0px 10px;
    }

    .show.dropdown {
      background-color: $color-natural-tone-red;

      .dropdown-menu.show {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        background-color: $color-natural-tone-red;

        padding: 0;
        width: 100%;
        border-radius: 0px;
        margin-top: -3px;
        //border: 1px solid $primary-white;
        border: none;

        .dropdown-item {
          color: $primary-white;
          padding: 10px 10px;
          display: flex;
          justify-content: space-between;

          &:hover {
            background-color: $color-natural-tone-red;
            color: $primary-white;
          }

          svg {
            path {
              stroke: $primary-white;
            }
          }
        }

        @media screen and (max-width: 645px) {
          width: 100%;
        }
      }
    }
  }
}