.video_wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/video/debeers-video-brand.mp4") repeat center center fixed;
  background-size: cover;
  top: 0;
  overflow: hidden;
}
.video_wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(100% 316.05% at 0% 100%, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
}
