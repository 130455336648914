// Global colors

// Primary colors
$primary-color: #12233a;
$secondary-color: #f37021;
$error-color: #d70e29;
$warning-color: #fbba00;
$success-color: #92c044;
$eternal-blue: #13233a;

// Yurii is this correct?
$primary-black: #111111;
$primary-white: #ffffff;

$primary-color-100: #f6f6f8;
$primary-color-200: #b5b6c1;
$primary-color-300: #999aa9;
$primary-color-400: #808394;
$primary-color-500: #696d81;
$primary-color-600: #555b70;
$primary-color-700: #414a60;
$primary-color-800: #2e3b53;
$primary-color-900: #192d46;
$primary-color-1000: #12233a;

$error-color-100: #fce6df;
$error-color-200: #f7d1c6;
$error-color-300: #f4bcaf;
$error-color-400: #f1a89a;
$error-color-500: #ed9386;
$error-color-600: #eb8175;
$error-color-700: #e86e64;
$error-color-800: #e65956;
$error-color-900: #e33f47;
$error-color-1000: #d70e29;

$warning-color-100: #fff4e3;
$warning-color-200: #ffeece;
$warning-color-300: #ffe6b9;
$warning-color-400: #ffe0a4;
$warning-color-500: #ffd98f;
$warning-color-600: #ffd27c;
$warning-color-700: #ffcc66;
$warning-color-800: #ffc54e;
$warning-color-900: #febe32;
$warning-color-1000: #fbba00;

$success-color-100: #eff5e8;
$success-color-200: #e4f0d8;
$success-color-300: #d7e8c6;
$success-color-400: #cce8c6;
$success-color-500: #c0dca4;
$success-color-600: #b5d795;
$success-color-700: #a9d185;
$success-color-800: #9ecc76;
$success-color-900: #92cc76;
$success-color-1000: #92c044;

$grey-30: #f8f8f8;
$grey-50: #f1f1f1;
$grey-100: #e1dfe0;
$grey-200: #c3c2c3;
$grey-300: #a8a7a7;
$grey-400: #8e8d8d;
$grey-500: #757474;
$grey-600: #5f5d5c;
$grey-700: #494847;
$grey-800: #333231;
$grey-900: #1d1e1b;
$grey-1000: #000000;
$spec-grey: #2D2E2D;

// ------------------------
// Secondary colors
// ------------------------

$orange-100: #ffeddf;
$orange-200: #fedec7;
$orange-300: #fdcfaf;
$orange-400: #fcc198;
$orange-500: #fab282;
$orange-600: #f9a56f;
$orange-700: #f7965b;
$orange-800: #f68a46;
$orange-900: #f47d32;
$orange-1000: #f37021;

$blue-100: #e0eaf7;
$blue-200: #c6d9f0;
$blue-300: #accae9;
$blue-400: #93bce3;
$blue-500: #79aedd;
$blue-600: #5fa3d8;
$blue-700: #3e98d2;
$blue-800: #008ece;
$blue-900: #0085c8;
$blue-1000: #0077bf;

$purple-100: #ebe5f2;
$purple-200: #dcd1e7;
$purple-300: #cebcdc;
$purple-400: #a9d1;
$purple-500: #b597c7;
$purple-600: #a986bd;
$purple-700: #9e77b4;
$purple-800: #9568ac;
$purple-900: #8b58a4;
$purple-1000: #884091;

// New brand colours
$color-pms-black-6: #101820;
$color-natural-tone-brown: #977035;
$color-highlight-tone-brown: #C49757;
$color-natural-tone-blue: #0C3C60;
$color-highlight-tone-blue: #1567A4;
$color-natural-tone-red: #9B2027;
$color-highlight-tone-red: #C62931;
