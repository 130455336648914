@font-face {
  font-family: "Jost*";
  font-style: normal;
  font-weight: 200;
  src: url("../font/Jost-200-Thin.otf");
}

@font-face {
  font-family: "Jost*";
  font-style: normal;
  font-weight: 400;
  src: url("../font//Jost-400-Book.otf");
}

@font-face {
  font-family: 'Jost*';
  font-weight: 600;
  src: url("../font/Jost-600-Semi.otf");
}

* {
  font-family: "Jost*";
}
