.side-platform-item {
  color: $primary-white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 4px;

  &:hover,&:visited {
    cursor: pointer;
    background-color: $primary-color-800;
    color: $primary-white;

    .icon {
      opacity: 1;
      right: 22px;
    }
  }

  .body-large.medium {
    font-family: "Jost*";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.3px;
    max-width: 200px;
  }

  .icon {
    position: absolute;
    transition: 300ms left, 100ms opacity;
    width: 12px;
    height: 12px;
    opacity: 0;
    top: calc(50% - 14px);
    right: -30px;
  }

  img {
    height: 56px;
    width: 56px;
    border-radius: 4px;
  }
}

.disable-stream-service:hover {
  cursor: not-allowed !important;
}
