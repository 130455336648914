.service-block {
  height: 435px;
  width: 260px;
  max-width: 260px;
  background-color: transparent;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border: 1px solid $primary-white;

  .image-block {
    height: 355px;
    width: 260px;
    transition: height 300ms;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .service-info-block {
    margin: 16px 16px 8px 16px;
    min-height: 60px;

    h5 {
      margin: 0;
      padding: 0;
    }
  }

  .service-info-block-text {
    margin: 0 16px 16px 16px;
    font-size: 16px;
    color: $grey-100;
    height: 0;
    transition: height 300ms, opacity 300ms;
    opacity: 0.0;
  }

  .service-top-margin {
    margin-top: -30px;
  }

  .button-wrapper {
    height: 0;
    padding: 0;
    transition: height 300ms, padding 300ms;

    button {
      opacity: 0;
      transition: opacity 300ms;
    }
  }

  &.active {
    .service-info-block-text {
      height: auto;
      opacity: 1.0;
    }

    .button-wrapper {
      height: 80px;
      padding: 0 16px 16px 16px;

      button {
        opacity: 1;
      }
    }

    .image-block {
      height: 294px;
      width: 260px;
    }
  }
}

.service-block-btn {
  border: 1px solid $primary-white;
}
